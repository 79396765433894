import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, EffectFlip, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-fade';

$(function () {
  new Swiper(".banner-swiper", {
    modules: [Autoplay, Navigation, Pagination, EffectFlip],
    effect: "flip",
    autoplay: {
      delay: 2000,
    },
    speed: 1000,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  new Swiper(".why-choose-swiper", {
    modules: [Autoplay, Navigation, Pagination, EffectFade],
    effect: false,
    autoplay: {
      delay: 2000,
    },
    speed: 800,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  new Swiper(".customer-swiper", {
    modules: [Autoplay, Navigation, Pagination, EffectFade],
    effect: false,
    autoplay: {
      delay: 2000,
    },
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 0,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    scrollbar: {
      el: ".swiper-scrollbar",
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
    },
  });

  $(".btn-open-menu").on("click", function () {
    $("#navbarCollapse").addClass("is-opened");
    $("body").addClass("menu-is-opened");
  });
  $(".btn-close-menu").on("click", function (e) {
    e.preventDefault();
    $("#navbarCollapse").removeClass("is-opened");
    $("body").removeClass("menu-is-opened");
  });
});
